import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TrainerService {
  domainUrl: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  getTrainers(page = 0, limit = 0, spName = "") {
    let queryUrl = "";
    if (page) queryUrl = `${queryUrl}page=${page}&`;
    if (limit) queryUrl = `${queryUrl}limit=${limit}&`;
    if (spName) queryUrl = `${queryUrl}sport=${spName}`;
    return this.http.get(`${this.domainUrl}/trainers?${queryUrl}`);
  }
  getAllTrainer() {
    return this.http.get(`${this.domainUrl}/trainers/all`);
  }
  getTrainersAccToSport(spName: string) {
    return this.http.get(`${this.domainUrl}/trainers?sport=${spName}`);
  }
  createTrainer(formData: FormData) {
    return this.http.post(`${this.domainUrl}/trainers/create`, formData);
  }
  updateTrainer(formData: any, id: string) {
    return this.http.post(`${this.domainUrl}/trainers/update/${id}`, formData);
  }
  deleteSport(id: string) {
    return this.http.delete(`${this.domainUrl}/trainers/delete/${id}`);
  }
}
