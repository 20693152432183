import { Component, TemplateRef } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { PasswordChangeService } from 'src/app/services/password-change.service'

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{6,10}$/

  submitted: boolean = false
  modalRef: any

  visible: any = {
    oldPassword: false,
    newPassword: false,
    retypePassword: false
  }
  passwordChangeForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(this.passwordRegex)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(this.passwordRegex)]),
    retypePassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(this.passwordRegex)])
  })

  get f() {
    return this.passwordChangeForm.controls
  }

  constructor(private toastrService: ToastrService, private passwordChangeService: PasswordChangeService, public router: Router, private modalService: BsModalService) {}

  confirmUpdatePassword() {
    this.passwordChangeService.updatePassword(this.passwordChangeForm.value).subscribe(
      (res: any) => {
        this.toastrService.success('Password is changed successfully.')
        this.modalRef.hide()
        setTimeout(() => {
          localStorage.removeItem('currentUser')
          this.router.navigateByUrl('/auth/login')
        }, 1000)
      },
      (err: any) => {
        this.toastrService.error(err.error.error.message)
      }
    )
  }

  openConfirmModel(popUp: TemplateRef<any>) {
    this.submitted = true
    if (this.passwordChangeForm.invalid) {
      this.toastrService.info('Fill all required field.')
      return
    }
    if (this.f.oldPassword.value.trim() == this.f.newPassword.value.trim()) {
      this.toastrService.error('Old password and new password must be different .')
      return
    }
    if (this.f.newPassword.value.trim() != this.f.retypePassword.value.trim()) {
      this.toastrService.error('New password and Retype password must be equal.')
      return
    }

    this.modalRef = this.modalService.show(popUp)
  }
}
