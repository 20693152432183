import { Component } from '@angular/core'
@Component({
  selector: 'app-layout',
  template: `
    <div class="container-fluid">
      <app-sidebar></app-sidebar>
    </div>
    <router-outlet></router-outlet>
  `
})
export class LayoutComponent {}
