import { Component, OnInit, TemplateRef } from '@angular/core'
import { AuthService } from 'src/app/auth/services/auth.service'
import { BsModalService } from 'ngx-bootstrap/modal'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(private authService: AuthService, public router: Router, private modalService: BsModalService) {}

  modalRef: any
  currentUser: any

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue
  }

  logout() {
    this.modalRef.hide()
    localStorage.removeItem('currentUser')
    this.router.navigateByUrl('/auth/login')
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
  confirm() {
    this.logout()
  }
  cancel() {
    this.modalRef.hide()
  }
}
