import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SidebarComponent } from './sidebar/sidebar.component'

// ngx bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { LayoutComponent } from './layout/layout.component'
import { HttpClientModule } from '@angular/common/http'
import { ModalModule } from 'ngx-bootstrap/modal'
import { Routes, RouterModule } from '@angular/router'

const routes: Routes = []

@NgModule({
  declarations: [SidebarComponent, LayoutComponent],
  imports: [CommonModule, BsDropdownModule.forRoot(), HttpClientModule, ModalModule.forRoot(), RouterModule.forChild(routes)],
  exports: [SidebarComponent, LayoutComponent, RouterModule]
})
export class SharedModule {}
