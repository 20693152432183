import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap/modal'
import { RouterModule, Routes } from '@angular/router'

import { LoginComponent } from './components/login/login.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { SharedModule } from '../shared/shared.module'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
]

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent],
  imports: [MatIconModule, MatFormFieldModule, CommonModule, SharedModule, FormsModule, ReactiveFormsModule, ModalModule.forRoot(), RouterModule.forChild(routes)]
})
export class AuthModule {}
