import { Component, OnInit } from '@angular/core'
import { PageChangedEvent } from 'ngx-bootstrap/pagination'
import { UserService } from 'src/app/services/user.service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  userList: any
  notFound: boolean = false
  totalCount: number
  currentPage: number = 1
  itemsPerPage: number = 10

  constructor(private userService: UserService, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.getUserList(this.currentPage, this.itemsPerPage)
  }

  pageChanged(event: PageChangedEvent): void {
    this.getUserList(event.page, event.itemsPerPage)
  }

  getUserList(page, pageLimit) {
    this.userService.getUsers(page, pageLimit).subscribe(
      (res: any) => {
        this.userList = res.payload.data
        this.notFound = false
        this.totalCount = res.payload.totalCount
        if (res.payload.totalCount == 0) {
          this.notFound = true
        }
      },
      (error) => {
        this.notFound = true
      }
    )
  }
}
