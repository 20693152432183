import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class UserService {
    url: any = environment.API_URL;
    constructor(private http: HttpClient) {}

    getUsers(page = 1, pageLimit = 10) {
        return this.http.get(
            `${this.url}/user?page=${page}&limit=${pageLimit}`
        );
    }
}
