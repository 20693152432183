import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { AuthService } from '../auth/services/auth.service'
import { Observable } from 'rxjs'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  currentUser: any

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser.accessToken
        }
      })
    }

    return next.handle(request)
  }
}
