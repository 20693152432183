import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { PageChangedEvent } from 'ngx-bootstrap/pagination'
import { ToastrService } from 'ngx-toastr'
import { SportService } from 'src/app/services/sport.service'
import { SharedService } from 'src/app/shared/services/shared.service'

@Component({
  selector: 'app-sport',
  templateUrl: './sport.component.html',
  styleUrls: ['./sport.component.scss']
})
export class SportComponent implements OnInit {
  sportList: any = []
  notFound: boolean = false
  totalCount: number = 0
  currentPage: number = 1
  itemsPerPage: number = 10
  modalRef: BsModalRef
  uploadedIcon: File = null
  uploadedFileType: any = ['image/png', 'image/jpeg', 'image/jpg']
  edit: boolean = false
  delSportId: string = ''
  previewPath: any
  addNew: boolean = false
  submitted: boolean = false

  sportForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(36)]),
    icon: new FormControl('', Validators.required)
  })
  prevFile: any
  editSportData: any
  elgSportIcon: string
  imgUpload: boolean = false

  get f() {
    return this.sportForm.controls
  }
  constructor(private sportService: SportService, private modalService: BsModalService, private sharedService: SharedService, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.getSportList(this.currentPage, this.itemsPerPage)
  }

  pageChanged(event: PageChangedEvent): void {
    this.getSportList(event.page, event.itemsPerPage)
  }

  getSportList(page: number, pageLimit: number) {
    this.sportService.getSports(page, pageLimit).subscribe(
      (res: any) => {
        this.sportList = res.payload.data
        this.totalCount = res.payload.totalCount
        if (!this.sportList.length) this.notFound = true
      },
      (error) => {
        this.notFound = true
      }
    )
  }

  openAddSportModel(template: TemplateRef<any>, data: any) {
    this.reset()
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: 'static', keyboard: false })
    this.addNew = true
  }
  createNewSport(): void {
    this.submitted = true
    if (this.sportForm.invalid) {
      this.toastrService.info('Please fill required fields')
      return
    }
    if (this.f.name.value.length > 36) {
      this.toastrService.info('Sport name should be less than 36 character')
      return
    }
    this.sportService.createSport(this.sportForm.value).subscribe(
      (res: any) => {
        if (!this.sportList.length) this.sportList.push(res.payload)
        else if (this.sportList.length <= this.itemsPerPage) {
          this.sportList.splice(this.itemsPerPage - 1, 1)
          this.sportList.splice(0, 0, res.payload)
        }
        if (this.sportList.length) this.notFound = false
        this.totalCount += 1
        this.modalRef.hide()
        this.toastrService.success('Sport is created successfully')
      },
      (error) => {
        this.toastrService.error(error.error.error.message)
      }
    )
  }

  openEditSport(template: TemplateRef<any>, sport: any) {
    this.reset()
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: 'static', keyboard: false })
    this.edit = true
    this.editSportData = sport
    this.sportForm.patchValue({ name: sport.name })
    this.previewPath = sport.icon
    this.f.icon.clearValidators()
  }
  editSport(): void {
    this.submitted = true
    if (this.sportForm.invalid) {
      this.toastrService.info('Please fill required fields')
      return
    }
    let updatedField = {}
    if (this.editSportData.name !== this.f.name.value) {
      if (this.f.name.value.length > 36) {
        this.toastrService.info('Sport name should be less than 36 character')
        return
      }
      updatedField['name'] = this.f.name.value
    }
    if (this.editSportData.icon !== this.previewPath) {
      updatedField['icon'] = this.previewPath
      updatedField['prevIcon'] = this.editSportData.icon
    }
    if (!Object.keys(updatedField).length) {
      this.toastrService.info('Update at least one field')
      return
    }
    this.sportService.updateSport(updatedField, this.editSportData._id).subscribe(
      (res: any) => {
        let index = this.sportList.findIndex((ele: { _id: any }) => ele._id == this.editSportData._id)
        this.sportList.splice(index, 1, res.payload)
        this.modalRef.hide()
        this.toastrService.success('Sport is updated successfully')
      },
      (error) => {
        this.toastrService.error(error.error.error.message)
      }
    )
  }

  openDeleteSport(template: TemplateRef<any>, sportId: string): void {
    this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, backdrop: 'static', keyboard: false })
    this.delSportId = sportId
  }
  confirmDel(): void {
    if (!this.delSportId) {
      this.toastrService.error('Select at least one sport')
      return
    }
    this.sportService.deleteSport(this.delSportId).subscribe(
      (res: any) => {
        this.getSportList(this.currentPage, this.itemsPerPage)
        this.modalRef.hide()
        this.toastrService.success('Sport is deleted successfully.')
      },
      (error) => {
        this.modalRef.hide()
        this.toastrService.error(error.error.error.message)
      }
    )
  }
  enlargeImg(popUp: TemplateRef<any>, sportIcon: string): void {
    this.elgSportIcon = sportIcon
    if (this.elgSportIcon) {
      this.modalRef = this.modalService.show(popUp, { class: 'modal-lg', ignoreBackdropClick: true, backdrop: 'static', keyboard: false })
    }
  }
  loading: boolean = true
  onLoad() {
    this.loading = false
  }

  onUploadIcon(e: any) {
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files
      if (!this.uploadedFileType.includes(file.type)) {
        this.toastrService.info('You can select only image file')
        return
      }
      this.sharedService.uploadFile(file, this.previewPath).subscribe(
        (res: any) => {
          this.previewPath = res.payload.uploadedUrl
          this.sportForm.patchValue({ icon: this.previewPath })
          this.imgUpload = true
        },
        (err: any) => {
          this.toastrService.error(err.error.error.message)
        }
      )
    }
  }
  reset(): void {
    this.sportForm.reset()
    this.addNew = false
    this.submitted = false
    this.edit = false
    this.uploadedIcon = null
    this.previewPath = ''
  }
  isFormDataEmpty(formData: any) {
    for (let entry of formData.entries()) {
      if (entry[1]) {
        return false
      }
    }
    return true
  }
  cancelModel() {
    if (this.previewPath && this.imgUpload) {
      this.sharedService.deleteUploadedFile(this.previewPath).subscribe(
        (res: any) => (this.previewPath = ''),
        (err: any) => (this.previewPath = '')
      )
    }
  }
}
