import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PasswordChangeService {
    url: any = environment.API_URL;
    constructor(private http: HttpClient) {}

    updatePassword(data: any) {
        delete data["retypePassword"];
        return this.http.post(`${this.url}/user/update-password`, data);
    }
}
