import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { environment } from '../../../environments/environment'
import { User } from '../models/user.model'

@Injectable()
export class AuthService {
  private userSubject: BehaviorSubject<User>
  public user: Observable<User>

  private emailSend = new Subject<any>()
  child1$ = this.emailSend.asObservable()

  SendToChild1(msg: string) {
    this.emailSend.next(msg)
  }

  url: any = environment.API_URL

  constructor(private http: HttpClient) {}

  public get currentUserValue() {
    let data = localStorage.getItem('currentUser')
    if (data !== 'undefined') return JSON.parse(localStorage.getItem('currentUser'))
    return false
  }

  public get basicAuthentication() {
    let data = {
      userName: '',
      password: ''
    }
    return data
  }

  public get language() {
    let lang = localStorage.getItem('lang')
    if (lang) {
      return lang
    }
    return false
  }

  login(user: User) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post<any>(this.url + '/user/signin', user, httpOptions)
  }

  reset(data: any) {
    return this.http.post(this.url + 'user/reset-password', data)
  }

  forgetPassword(data: any) {
    return this.http.post(`${this.url}/user/pass/forgot`, data)
  }

  logout(data: any) {
    localStorage.removeItem('currentUser')
    return this.http.post(this.url + 'user/logout', data)
  }

  updateuser(data: any) {
    return this.http.patch(this.url + 'user/update', data)
  }

  changePassword(data: any) {
    return this.http.post(this.url + 'user/change-password', data)
  }

  emailValidation(data: any) {
    return this.http.post(this.url + 'user/emailExists', data)
  }
}
