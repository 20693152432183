<div class="content">
  <div class="password-change">
    <div class="box">
      <div class="d-flex justify-content-center">
        <h2>Change Password</h2>
      </div>
      <form [formGroup]="passwordChangeForm" (submit)="openConfirmModel(template)">
        <div class="form-group">
          <label for="oldPassword">Old Password: <span style="color: red">*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="oldPassword" [type]="visible.oldPassword ? 'text' : 'password'" class="form-control" formControlName="oldPassword" placeholder="Enter old password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.oldPassword" (click)="visible.oldPassword = !visible.oldPassword" src="../../../assets/ludus_icon/view-icon.svg" class="m-2" alt="" />
              <img *ngIf="visible.oldPassword" (click)="visible.oldPassword = !visible.oldPassword" src="../../../assets/ludus_icon/view-icon-fill.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.oldPassword.errors" class="alert alert-danger">
            <span *ngIf="f.oldPassword.errors.required"> Old password is required </span>
            <span *ngIf="f.oldPassword.errors.minlength || f.oldPassword.errors.maxlength"> Length should be (6 to 10), </span>
            <span *ngIf="f.oldPassword.errors.pattern"> One capital letter, One small letter, One number, One special character </span>
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password: <span style="color: red">*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="newPassword" [type]="visible.newPassword ? 'text' : 'password'" class="form-control" formControlName="newPassword" placeholder="Enter new password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.newPassword" (click)="visible.newPassword = !visible.newPassword" src="../../../assets/ludus_icon/view-icon.svg" class="m-2" alt="" />
              <img *ngIf="visible.newPassword" (click)="visible.newPassword = !visible.newPassword" src="../../../assets/ludus_icon/view-icon-fill.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.newPassword.errors" class="alert alert-danger">
            <span *ngIf="f.newPassword.errors.required"> New password is required </span>
            <span *ngIf="f.newPassword.errors.minlength || f.newPassword.errors.maxlength"> Length should be (6 to 10), </span>
            <span *ngIf="f.newPassword.errors.pattern"> One capital letter, One small letter, One number, One special character </span>
          </div>
        </div>
        <div class="form-group">
          <label for="retypePassword">Retype Password: <span style="color: red">*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="retypePassword" [type]="visible.retypePassword ? 'text' : 'password'" class="form-control" formControlName="retypePassword" placeholder="Retype new password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.retypePassword" (click)="visible.retypePassword = !visible.retypePassword" src="../../../assets/ludus_icon/view-icon.svg" class="m-2" alt="" />
              <img *ngIf="visible.retypePassword" (click)="visible.retypePassword = !visible.retypePassword" src="../../../assets/ludus_icon/view-icon-fill.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.retypePassword.errors" class="alert alert-danger">
            <span *ngIf="f.retypePassword.errors.required"> Retype password is required </span>
            <span *ngIf="f.retypePassword.errors.minlength || f.retypePassword.errors.maxlength"> Length should be (6 to 10), </span>
            <span *ngIf="f.retypePassword.errors.pattern"> One capital letter, One small letter, One number, One special character </span>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-primary">Save Changes</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Change Password</h2>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../assets/images/create-successfull-img.svg" alt="" />
    <p>
      Are you sure you want<br />
      to change your password?
    </p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-cancel" (click)="modalRef.hide()">No</button>
    <button class="btn btn-green ms-2" (click)="confirmUpdatePassword()">Yes</button>
  </div>
</ng-template>
