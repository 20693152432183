import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SportService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  getSports(page = 1, pageLimit = 10) {
    return this.http.get(`${this.url}/sports?page=${page}&limit=${pageLimit}`);
  }
  getAllSports() {
    return this.http.get(`${this.url}/sports`);
  }
  getAllSport() {
    return this.http.get(`${this.url}/sports/all`);
  }
  createSport(tempForm) {
    return this.http.post(`${this.url}/sports/create`, tempForm);
  }
  updateSport(tempForm, id) {
    return this.http.post(`${this.url}/sports/update/${id}`, tempForm);
  }
  deleteSport(id) {
    return this.http.delete(`${this.url}/sports/delete/${id}`);
  }
}
