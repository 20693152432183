import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  uploadImage(file: any) {
    return this.http.post(this.url + "upload/image", file);
  }

  uploadVideo(file: any) {
    return this.http.post(this.url + "upload/video", file);
  }

  uploadPdf(file: any) {
    return this.http.post(this.url + "upload/pdf", file);
  }

  uploadFile(file: any, previousFileUrl: string = "") {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("previousFileUrl", previousFileUrl);
    return this.http.post(this.url + "/upload-file", formData);
  }
  deleteUploadedFile(previousFileUrl: string = "") {
    return this.http.post(`${this.url}/delete-uploaded-file`, { previousFileUrl });
  }
}
