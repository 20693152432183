<div class="content">
  <div class="d-flex mb-2">
    <div class="border rounded p-2">
      <span
        >Total: <strong>{{ totalCount || 0 }}</strong></span
      >
    </div>
  </div>
  <div class="table-responsive mb-3">
    <table class="table border table-hover" aria-label="">
      <thead>
        <tr>
          <th scope="col" style="width: 90px">S No.</th>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Email</th>
          <th scope="col">Date of birth</th>
          <th scope="col">Nationality</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of userList; let i = index">
          <td>{{ (currentPage - 1) * itemsPerPage + (i + 1) }}.</td>
          <td>
            <span class="text-capitalize">{{ user.fn ? user.fn : '-' }}</span>
          </td>
          <td>
            <span class="text-capitalize">{{ user.ln ? user.ln : '-' }}</span>
          </td>
          <td>
            <span>{{ user.email ? user.email : '-' }}</span>
          </td>
          <td>{{ user.dob ? user.dob : '-' }}</td>
          <td>
            <span class="text-capitalize">{{ user.country ? user.country : '-' }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="notFound">
    <img src="../../../../../assets/images/not-found.svg" alt="" />
  </div>

  <pagination
    *ngIf="!notFound && totalCount > itemsPerPage"
    [boundaryLinks]="true"
    [totalItems]="totalCount"
    [itemsPerPage]="itemsPerPage"
    [(ngModel)]="currentPage"
    (pageChanged)="pageChanged($event)"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    [maxSize]="10"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>
